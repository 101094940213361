"use strict";
/*global  jQuery*/
jQuery(function ($) {
$(function () {
 //読み込みが完了
 $(window).on('load',function () { 
   console.log('Top Page Loaded.');  
 });
 $('#topslide').slick({
     arrows:false,
    dots: true,
    infinite: true,
    speed: 1500,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover:false,
  });

});
});

